@use '~@angular/material' as mat;
@import 'settings/colors';

.cdk-overlay-pane {
  .mtx-popover-panel {
    @include mat.elevation(10, $color-3amblue, 0.7);

    padding: 0;
    border-radius: 8px;
    overflow: hidden;
  }
}
