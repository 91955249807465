@use '~@angular/material' as mat;
@import 'settings/colors';

.mat-tooltip {
  &.large {
    @include mat.elevation(8, $color-3amblue, 0.3);

    background-color: white;
    border-radius: 15px;
    color: $color-3amblue;
    font-size: 14px;
    font-weight: 500;
    max-width: 300px;
    padding: 20px 25px;
  }

  &.dark {
    background-color: $color-3amblue;
    color: white;
    font-size: 12px;
    font-weight: 500;
  }
}
