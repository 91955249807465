@import 'settings/colors';

.mat-expansion-panel {
  background-color: transparent;
  box-shadow: none !important;

  .mat-expansion-panel-header {
    background-color: #ececec;
    border-radius: 8px;
    height: 72px;
    padding: 24px 28px;
  }

  &:not(.mat-expanded)
    .mat-expansion-panel-header:hover:not([aria-disabled='true']) {
    background-color: $color-city-concrete;
  }

  &.mat-expanded .mat-expansion-panel-header:focus,
  &.mat-expanded .mat-expansion-panel-header:hover,
  &.mat-expanded .mat-expansion-panel-header {
    background-color: $color-sun-yellow;
  }

  .mat-expansion-panel-body {
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    border-radius: 8px 8px 0px 0px;
    margin: 8px 0 0;
    padding: 32px;
  }
}
