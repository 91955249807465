.mat-date-range-input {
  height: var(--input-height);
  line-height: var(--input-height);

  .mat-date-range-input-inner {
    vertical-align: middle;
  }

  // Remove the negative margin top to ensure all input sit aligned correctly.
  .mat-input-element:not(.mat-end-date) {
    margin-top: 0;
  }
}
