.mat-button-base.mat-flat-button {
  $button-height: 48px !default;

  border-radius: var(--button-height, #{$button-height});
  font-size: var(--button-font-size, 16px);
  font-weight: var(--button-font-weight, 600);
  letter-spacing: var(--button-letter-spacing, normal);
  line-height: var(--button-height, #{$button-height});
  padding: 0 calc(var(--button-height, #{$button-height}) / 2);

  &[square] {
    border-radius: 8px;
  }
}
