// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '~@angular/material' as mat;
// Plus imports for other components in your app.

@import 'settings/fonts';
@import 'settings/colors';

$font-config: mat.define-typography-config(
  $font-family: $primary-font,
  $display-1:
    mat.define-typography-level(
      $font-size: 34px,
      $font-weight: 600,
      $line-height: normal,
    ),
  $display-2:
    mat.define-typography-level(
      $font-size: 45px,
      $font-weight: 600,
      $line-height: normal,
    ),
  $display-3:
    mat.define-typography-level(
      $font-size: 56px,
      $font-weight: 600,
      $line-height: normal,
    ),
  $display-4:
    mat.define-typography-level(
      $font-size: 112px,
      $font-weight: 400,
      $line-height: normal,
    ),
  $headline:
    mat.define-typography-level(
      $font-size: 24px,
      $font-weight: 500,
      $line-height: normal,
    ),
  $title:
    mat.define-typography-level(
      $font-size: 20px,
      $font-weight: 600,
      $line-height: normal,
    ),
  $subheading-1:
    mat.define-typography-level(
      $font-size: 18px,
      $font-weight: 600,
      $line-height: normal,
    ),
  $subheading-2:
    mat.define-typography-level(
      $font-size: 20px,
      $font-weight: 500,
      $line-height: 1.4,
    ),
  $body-1:
    mat.define-typography-level(
      $font-size: 16px,
      $font-weight: 400,
      $line-height: 1.5,
    ),
  $body-2:
    mat.define-typography-level(
      $font-size: 16px,
      $font-weight: 600,
      $line-height: 1.5,
    ),
  $caption:
    mat.define-typography-level(
      $font-size: 12px,
      $font-weight: 500,
      $line-height: normal,
    ),
  $button:
    mat.define-typography-level(
      $font-size: 16px,
      $font-weight: 600,
    ),
);

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core($font-config);

// Generated theme by Material Theme Generator with some custom modifications
// https://materialtheme.arcsine.dev
// Fork at: https://materialtheme.arcsine.dev/?c=YHBhbGV0dGU$YHByaW1hcnk$YF48IzIxMjIzOCIsIj9lcjwjYmNiZGMzIiwiO2VyPCMxNDE0MjR$LCIlPmBePCNmZmU1MDAiLCI~ZXI8I2ZmZjdiMyIsIjtlcjwjZmZkYTAwfiwid2Fybj5gXjwjYmY1NzQwIiwiP2VyPCNlY2NkYzYiLCI7ZXI8I2E5M2MyYX4sIj9UZXh0PCMyMTIyMzgiLCI~PTwjZjVmNWY1IiwiO1RleHQ8I2ZmZmZmZiIsIjs9PCMyMTIyMzh$LCJmb250cz5bYEA8KC00fixgQDwoLTN$LGBAPCgtMn4sYEA8KC0xfixgQDxoZWFkbGluZX4sYEA8dGl0bGV$LGBAPHN1YiktMn4sYEA8c3ViKS0xfixgQDxib2R5LTJ$LGBAPGJvZHktMX4sYEA8YnV0dG9ufixgQDxjYXB0aW9ufixgQDxpbnB1dCIsInNpemU$bnVsbH1dLCJpY29uczxPdXRsaW5lZCIsIj9uZXNzPnRydWUsInZlcnNpb24$MTJ9

// Light Theme Text
$dark-text: #212238;
$dark-primary-text: $dark-text;
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

$mat-light-theme-foreground: (
  base: black,
  divider: $dark-dividers,
  dividers: $dark-dividers,
  disabled: $dark-disabled-text,
  disabled-button: rgba($dark-text, 0.26),
  disabled-text: $dark-disabled-text,
  elevation: black,
  secondary-text: $dark-accent-text,
  hint-text: $dark-disabled-text,
  accent-text: $dark-accent-text,
  icon: $dark-accent-text,
  icons: $dark-accent-text,
  text: $dark-primary-text,
  slider-min: $dark-primary-text,
  slider-off: rgba($dark-text, 0.26),
  slider-off-active: $dark-disabled-text,
);

// Dark Theme text
$light-text: white;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

$mat-dark-theme-foreground: (
  base: $light-text,
  divider: $light-dividers,
  dividers: $light-dividers,
  disabled: $light-disabled-text,
  disabled-button: rgba($light-text, 0.3),
  disabled-text: $light-disabled-text,
  elevation: black,
  hint-text: $light-disabled-text,
  secondary-text: $light-accent-text,
  accent-text: $light-accent-text,
  icon: $light-text,
  icons: $light-text,
  text: $light-text,
  slider-min: $light-text,
  slider-off: rgba($light-text, 0.3),
  slider-off-active: rgba($light-text, 0.3),
);

// Background config
// Light bg
$light-background: $color-background;
$light-bg-darker-5: darken($light-background, 5%);
$light-bg-darker-10: darken($light-background, 10%);
$light-bg-darker-20: darken($light-background, 20%);
$light-bg-darker-30: darken($light-background, 30%);
$light-bg-lighter-5: lighten($light-background, 5%);
$dark-bg-tooltip: lighten(#212238, 20%);
$dark-bg-alpha-4: rgba(#212238, 0.04);
$dark-bg-alpha-12: rgba(#212238, 0.12);

$mat-light-theme-background: (
  background: $light-background,
  status-bar: $light-bg-darker-20,
  app-bar: $light-bg-darker-5,
  hover: $dark-bg-alpha-4,
  card: $light-bg-lighter-5,
  dialog: $light-bg-lighter-5,
  tooltip: $dark-bg-tooltip,
  disabled-button: $dark-bg-alpha-12,
  raised-button: $light-bg-lighter-5,
  focused-button: $dark-focused,
  selected-button: $light-bg-darker-20,
  selected-disabled-button: $light-bg-darker-30,
  disabled-button-toggle: $light-bg-darker-10,
  unselected-chip: $light-bg-darker-10,
  disabled-list-option: $light-bg-darker-10,
);

// Dark bg
$dark-background: #212238;
$dark-bg-lighter-5: lighten($dark-background, 5%);
$dark-bg-lighter-10: lighten($dark-background, 10%);
$dark-bg-lighter-20: lighten($dark-background, 20%);
$dark-bg-lighter-30: lighten($dark-background, 30%);
$light-bg-alpha-4: rgba(white, 0.04);
$light-bg-alpha-12: rgba(white, 0.12);

// Background palette for dark themes.
$mat-dark-theme-background: (
  background: $dark-background,
  status-bar: $dark-bg-lighter-20,
  app-bar: $dark-bg-lighter-5,
  hover: $light-bg-alpha-4,
  card: $dark-bg-lighter-5,
  dialog: $dark-bg-lighter-5,
  tooltip: $dark-bg-lighter-20,
  disabled-button: $light-bg-alpha-12,
  raised-button: $dark-bg-lighter-5,
  focused-button: $light-focused,
  selected-button: $dark-bg-lighter-20,
  selected-disabled-button: $dark-bg-lighter-30,
  disabled-button-toggle: $dark-bg-lighter-10,
  unselected-chip: $dark-bg-lighter-20,
  disabled-list-option: $dark-bg-lighter-10,
);

body {
  --primary-color: #212238;
  --primary-lighter-color: #bcbdc3;
  --primary-darker-color: #141424;
  --text-primary-color: #{$light-primary-text};
  --text-primary-lighter-color: #{$dark-primary-text};
  --text-primary-darker-color: #{$light-primary-text};
}

// Contrast is for theming slide toggle,
$mat-primary: (
  main: #212238,
  lighter: #bcbdc3,
  darker: #141424,
  200: #212238,
  contrast: (
    main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  ),
);

$webapp-primary: mat.define-palette($mat-primary, main, lighter, darker);

body {
  --accent-color: #ffe500;
  --accent-lighter-color: #fff7b3;
  --accent-darker-color: #ffda00;
  --text-accent-color: #{$dark-primary-text};
  --text-accent-lighter-color: #{$dark-primary-text};
  --text-accent-darker-color: #{$dark-primary-text};
}

// Contrast is for theming slide toggle,
$mat-accent: (
  main: #ffe500,
  lighter: #fff7b3,
  darker: #ffda00,
  200: #ffe500,
  contrast: (
    main: $dark-primary-text,
    lighter: $dark-primary-text,
    darker: $dark-primary-text,
  ),
);

$webapp-accent: mat.define-palette($mat-accent, main, lighter, darker);

// The warn palette is optional (defaults to red).
$webapp-warn: mat.define-palette(mat.$red-palette);

$light-theme: (
  primary: $webapp-primary,
  accent: $webapp-accent,
  warn: $webapp-warn,
  is-dark: false,
  foreground: $mat-light-theme-foreground,
  background: $mat-light-theme-background,
);

$dark-theme: (
  primary: $webapp-primary,
  accent: $webapp-accent,
  warn: $webapp-warn,
  is-dark: true,
  foreground: $mat-dark-theme-foreground,
  background: $mat-dark-theme-background,
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($light-theme);

// [theme='dark'] {
//   @include mat.all-component-themes($dark-theme);
// }
