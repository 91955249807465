$primary-font: 'SharpSans', 'Helvetica Neue', sans-serif;

// Font faces should only be fetched by the browsers if rendering text using the
// font, not from the declaration alone.
@font-face {
  font-family: 'SharpSans';
  font-weight: 100;
  src: url('/assets/fonts/SharpSans-Thin.woff2') format('woff2'),
    url('/assets/fonts/SharpSans-Thin.woff') format('woff');
}

@font-face {
  font-family: 'SharpSans';
  font-style: italic;
  font-weight: 100;
  src: url('/assets/fonts/SharpSans-ThinItalic.woff2') format('woff2'),
    url('/assets/fonts/SharpSans-ThinItalic.woff') format('woff');
}

@font-face {
  font-family: 'SharpSans';
  font-weight: 200;
  src: url('/assets/fonts/SharpSans-Light.woff2') format('woff2'),
    url('/assets/fonts/SharpSans-Light.woff') format('woff');
}

@font-face {
  font-family: 'SharpSans';
  font-style: italic;
  font-weight: 200;
  src: url('/assets/fonts/SharpSans-LightItalic.woff2') format('woff2'),
    url('/assets/fonts/SharpSans-LightItalic.woff') format('woff');
}

@font-face {
  font-family: 'SharpSans';
  font-weight: 300;
  src: url('/assets/fonts/SharpSans-Book.woff2') format('woff2'),
    url('/assets/fonts/SharpSans-Book.woff') format('woff');
}

@font-face {
  font-family: 'SharpSans';
  font-style: italic;
  font-weight: 300;
  src: url('/assets/fonts/SharpSans-BookItalic.woff2') format('woff2'),
    url('/assets/fonts/SharpSans-BookItalic.woff') format('woff');
}

@font-face {
  font-family: 'SharpSans';
  font-weight: 400;
  src: url('/assets/fonts/SharpSans-Medium.woff2') format('woff2'),
    url('/assets/fonts/SharpSans-Medium.woff') format('woff');
}

@font-face {
  font-family: 'SharpSans';
  font-style: italic;
  font-weight: 400;
  src: url('/assets/fonts/SharpSans-MediumItalic.woff2') format('woff2'),
    url('/assets/fonts/SharpSans-MediumItalic.woff') format('woff');
}

@font-face {
  font-family: 'SharpSans';
  font-weight: 500;
  src: url('/assets/fonts/SharpSans-Semibold.woff2') format('woff2'),
    url('/assets/fonts/SharpSans-Semibold.woff') format('woff');
}

@font-face {
  font-family: 'SharpSans';
  font-style: italic;
  font-weight: 500;
  src: url('/assets/fonts/SharpSans-SemiboldItalic.woff2') format('woff2'),
    url('/assets/fonts/SharpSans-SemiboldItalic.woff') format('woff');
}

@font-face {
  font-family: 'SharpSans';
  font-weight: 600;
  src: url('/assets/fonts/SharpSans-Bold.woff2') format('woff2'),
    url('/assets/fonts/SharpSans-Bold.woff') format('woff');
}

@font-face {
  font-family: 'SharpSans';
  font-style: italic;
  font-weight: 600;
  src: url('/assets/fonts/SharpSans-BoldItalic.woff2') format('woff2'),
    url('/assets/fonts/SharpSans-BoldItalic.woff') format('woff');
}

@font-face {
  font-family: 'SharpSans';
  font-weight: 700;
  src: url('/assets/fonts/SharpSans-Extrabold.woff2') format('woff2'),
    url('/assets/fonts/SharpSans-Extrabold.woff') format('woff');
}

@font-face {
  font-family: 'SharpSans';
  font-style: italic;
  font-weight: 700;
  src: url('/assets/fonts/SharpSans-ExtraboldItalic.woff2') format('woff2'),
    url('/assets/fonts/SharpSans-ExtraboldItalic.woff') format('woff');
}
