// Core brand colors
$color-3amblue: #212238;
$color-cloud: whitesmoke; // #f5f5f5f5
$color-sun-yellow: #ffe500;
$color-misty-blue: #bdd4f2;
$color-sky-blue: #80def2;
$color-fresh-blue: #2959ff;
$color-red-sky: #bf5740;
$color-red-brick: #8f2624;
$color-orange-glow: #e08540;
$color-woodland-leaf: #8fcc00;
$color-city-concrete: #e5e5de;
$color-natural-stone: #cfccab;

$color-background: $color-cloud;
$color-border: rgba($color-3amblue, 0.2);
$color-line: #ececec;
