@import 'variables';

/**
 * The possible breakpoing sizes are: sm, md, lg, xl, xxl, xxxl
 *
 * The grid layout contains the following classes:
 *
 * .grid        - Set the element to display: grid with 12 columns
 * .col-x       - Set the default number of columns for an element [12]
 * .col-sm-x    - Set the number of columns for the breakpoint and above
 * .offset-x    - Shift the column forward in the grid
 * .offset-sm-x - Shift the column forward for the breakpoint and above
 * .order-x     - Set the column order
 * .order-sm-x  - Set the column order for the size and above
 */

// Generate container classes
@each $width in map-keys($grid-widths) {
  $max-width: map-get($grid-widths, $width);

  .container-#{$width} {
    margin: 0 auto;
    max-width: $max-width;
    padding: 0 $column-gap;
    width: 100%;
  }
}

.grid,
[class^='grid-'] {
  grid-template-columns: repeat($columns, minmax(5px, 1fr));
  display: grid;
  grid-column-gap: $column-gap;
  grid-row-gap: $column-gap;
  width: 100%;

  > * {
    grid-column-end: span 12;
  }
}

@for $i from 1 through $columns {
  .grid-#{$i} {
    grid-template-columns: repeat($i, minmax(1px, 1fr));

    > * {
      grid-column-end: span $i;
    }
  }
}

// Generate default columns
@for $i from 1 through $columns {
  .col-#{$i} {
    grid-column-end: span $i;
  }
}

// Generate default offsets
@for $i from 1 through ($columns - 1) {
  .offset-#{$i} {
    grid-column-start: $i + 1;
  }
}

// Generate default orders
@for $i from 1 through ($columns - 1) {
  .order-#{$i} {
    order: $i - 1;
  }
}

// Generate at each breakpoint
@each $breakpoint in map-keys($grid-breakpoints) {
  $min: map-get($grid-breakpoints, $breakpoint);

  @media screen and (min-width: $min) {
    // Generate grids
    @for $i from 1 through $columns {
      .grid-#{$breakpoint}-#{$i} {
        grid-template-columns: repeat($i, minmax(1px, 1fr));

        > * {
          grid-column-end: span $i;
        }
      }
    }

    // Generate columns
    @for $i from 1 through $columns {
      .col-#{$breakpoint}-#{$i} {
        grid-column-end: span $i;
      }
    }

    .offset-#{$breakpoint}-0 {
      grid-column-start: 1;
    }

    .offset-#{$breakpoint}-auto {
      grid-column-start: auto;
    }

    // Generate offsets
    @for $i from 1 through ($columns - 1) {
      .offset-#{$breakpoint}-#{$i} {
        grid-column-start: $i + 1;
      }

      .order-#{$breakpoint}-#{$i - 1} {
        order: $i - 1;
      }
    }
  }
}
