@import 'settings/colors';

mat-select {
  .mat-select-value {
    max-width: none;
  }

  .mat-select-arrow-wrapper {
    display: none;
  }
}
