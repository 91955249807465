@import 'settings/colors';

.no-form-padding .mat-form-field {
  margin-bottom: -1.5em;
}

.mat-form-field.mat-form-field-appearance-fill {
  .mat-form-field-flex {
    background-color: var(--input-background-color, $color-cloud);
  }

  .mat-form-field-flex {
    border-radius: var(--border-radius, 4px 4px 0 0);
  }

  &:not(.mat-form-field-invalid) .mat-form-field-underline {
    display: none;
  }
}

.mat-form-field-subscript-wrapper {
  // TODO: Use a map to sort all absolute z indexes
  z-index: 20;
}
