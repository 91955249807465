@import 'grid/variables';

strong {
  font-weight: 600;
}

h1 {
  font-size: 28px;
  font-weight: 600;
}

h2 {
  font-size: 24px;
  font-weight: 600;
}

h3 {
  font-size: 22px;
  font-weight: 600;
  margin: 0 0 8px;
}

h4 {
  font-size: 20px;
}

h5 {
  font-size: 18px;
  font-weight: 600;
}

p.xl {
  font-size: 20px;
}

p.lg {
  font-size: 18px;
}

p {
  font-size: 16px;
}

p.sm {
  font-size: 14px;
}

@media screen and (min-width: $breakpoint-sm) {
  h1 {
    font-size: 32px;
  }

  h2 {
    font-size: 28px;
  }

  h3 {
    font-size: 24px;
    margin: 0 0 16px;
  }

  h4 {
    font-size: 22px;
  }

  h5 {
    font-size: 20px;
  }
}

@media screen and (min-width: $breakpoint-md) {
  h1 {
    font-size: 44px;
  }

  h2 {
    font-size: 34px;
  }

  h3 {
    font-size: 28px;
  }

  h4 {
    font-size: 25px;
  }

  h5 {
    font-size: 22px;
  }

  p.xl {
    font-size: 22px;
  }

  p.lg {
    font-size: 20px;
  }

  p {
    font-size: 18px;
  }

  p.sm {
    font-size: 16px;
  }
}

@media screen and (min-width: $breakpoint-lg) {
  h1 {
    font-size: 44px;
  }

  h2 {
    font-size: 36px;
  }

  h3 {
    font-size: 32px;
  }

  h4 {
    font-size: 28px;
  }

  h5 {
    font-size: 24px;
  }

  p.xl {
    font-size: 24px;
  }

  p.lg {
    font-size: 22px;
  }

  p {
    font-size: 20px;
  }

  p.sm {
    font-size: 16px;
  }
}

@media screen and (min-width: $breakpoint-xl) {
  h1 {
    font-size: 48px;
  }

  h2 {
    font-size: 40px;
  }

  h3 {
    font-size: 38px;
  }

  h4 {
    font-size: 32px;
  }

  h5 {
    font-size: 26px;
  }

  p.xl {
    font-size: 26px;
  }

  p.lg {
    font-size: 22px;
  }

  p {
    font-size: 20px;
  }

  p.sm {
    font-size: 16px;
  }
}

@media screen and (min-width: $breakpoint-xxl) {
  h1 {
    font-size: 56px;
  }

  h2 {
    font-size: 45px;
  }

  h3 {
    font-size: 42px;
  }

  h4 {
    font-size: 35px;
  }

  h5 {
    font-size: 28px;
    line-height: 37px;
  }

  p.xl {
    font-size: 28px;
  }

  p.lg {
    font-size: 24px;
  }

  p {
    font-size: 20px;
  }

  p.sm {
    font-size: 16px;
  }
}
