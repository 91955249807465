@import 'settings/colors';
@import 'settings/fonts';

html,
body {
  height: 100%;
}

body {
  --nav-bar-width: 64px;
  color: $color-3amblue;
  font-family: $primary-font;
  -webkit-font-smoothing: antialiased;
  margin: 0;
}

// Google maps
.gm-style-iw,
.gm-style-iw-d {
  padding: 0 !important;
  overflow: hidden !important;
}

body div#hubspot-messages-iframe-container {
  @media screen and (max-width: 1024px) {
    display: none !important;
  }
}

@media print {
  @page {
    size: A4; /* DIN A4 standard, Europe */
    margin: 0;
  }
}
