.mat-checkbox-layout {
  font-size: var(--checkbox-font-size, 14px);
  font-weight: var(--checkbox-font-weight, 500);
  padding: var(--checkbox-padding, 8px 15px);

  .mat-checkbox-inner-container {
    height: var(--checkbox-size, 20px);
    width: var(--checkbox-size, 20px);
  }
}
