@import 'settings/colors';

mat-radio-group {
  gap: 10px;
}

mat-radio-button {
  position: relative;

  &.mat-radio-checked .mat-radio-inner-circle {
    transform: scale(1);
  }

  .mat-radio-outer-circle {
    border-color: rgba($color-3amblue, 0.4);
  }

  &.mat-radio-button .mat-radio-ripple {
    display: none;
  }

  .mat-radio-label {
    align-items: flex-start;
    display: flex;
    white-space: initial;
  }

  .mat-radio-container {
    top: 2px;
  }

  .mat-radio-label-content {
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0.5px;
    margin: 1px 0 0;
    opacity: 0.68;
  }

  &.mat-radio-checked .mat-radio-label-content {
    font-weight: 500;
    opacity: 1;
  }

  .tick {
    height: 12px;
    left: 4px;
    opacity: 0;
    position: absolute;
    top: 7px;
    transition: opacity 0.2s ease 0.1s;
    width: 12px;
  }

  &.mat-radio-checked svg {
    opacity: 1;
  }
}
