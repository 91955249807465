// Used as container widths. These can differ to the breakpoints if desired
$grid-widths: (
  sm: 600px,
  md: 800px,
  lg: 1024px,
  xl: 1200px,
  xxl: 1440px,
  xxxl: 1720px,
);

// The grid column breakpoints that generate the .col-sm-1... classes
$grid-breakpoints: (
  sm: 600px,
  md: 800px,
  lg: 1024px,
  xl: 1200px,
  xxl: 1440px,
  xxxl: 1720px,
);

// Breakpoints that are exported for use in each project. These should all be
// used as min-width media queries to match the columns which are mobile first
$breakpoint-sm: 600px;
$breakpoint-md: 800px;
$breakpoint-lg: 1024px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1440px;
$breakpoint-xxxl: 1720px;

$columns: 12;
$column-gap: 1.5rem;
